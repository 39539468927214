<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.lendOrderCode" class="input" placeholder="借出单号" clearable></el-input>
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-input v-model="queryInfo.assetSn" class="input" placeholder="资产码" clearable></el-input>
      <el-button type="primary" @click="currentChange(1)" :loading="loading">查询</el-button>
      <el-button type="primary" @click="dialogVisible = true">添加</el-button>
    </el-row>

    <el-table :data="priorityRuleList" border style="width: 100%" stripe>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="assetSn" label="资产码"></el-table-column>
      <el-table-column align="center" prop="amount" label="数量"></el-table-column>
      <el-table-column align="center" prop="targetStoreName" label="目标库区"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>
    <el-dialog title="添加优先库规则" :visible.sync="dialogVisible" :close-on-click-modal="false" width="800px" :before-close="handleClose">
      <el-form v-model="formData" label-width="200px">
        <el-form-item label="借出单号：" :required="true">
          <el-input v-model="formData.lendOrderCode" placeholder="请输入借出单号" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="备件编码：" :required="true">
          <el-input v-model="formData.materialCode" placeholder="请输入备件编码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="资产码：">
          <el-input v-model="formData.assetSn" @change="assetSnChange" placeholder="请输入资产码" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="数量：" :required="true">
          <el-input v-model="formData.amount" :disabled="this.formData.assetSn!=''" type="number" max="10000" min="0" placeholder="请输入数量" style="width: 350px" clearable></el-input>
        </el-form-item>
        <el-form-item label="目标库区：" :required="true">
          <el-select v-model="formData.targetStore" placeholder="请选择目标库区" style="width: 350px" filterable>
            <el-option
                v-for="item in storeTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addRule">确定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
export default {
  name: "PriorityRule",
  data() {
    return {
      queryInfo: {
        lendOrderCode: '',
        materialCode: '',
        assetSn: '',
        pageNo: 1
      },
      formData: {
        lendOrderCode: '',
        materialCode: '',
        assetSn: '',
        amount: '',
        targetStore: ''
      },
      priorityRuleList: [],
      dialogVisible: false,
      loading: false,
      pageSize: 0,
      total: 0
    }
  },
  computed: {
    storeTypeList() {
      return this.$store.getters.getDictList('storeType') || [];
    },
  },
  created() {
    this.search();
  },
  methods: {
    assetSnChange() {
      if (this.formData.assetSn) {
        this.formData.amount = 1
      }
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    async search() {
      const {data: res} = await this.$axios.post('rulePriority/queryRule', this.queryInfo);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.priorityRuleList = res.data.records;
      this.total = res.data.total;
      this.pageSize = res.data.size;
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('rulePriority/deleteRule', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    async addRule() {
      if (!this.formData.lendOrderCode) {
        return this.$message.error('借出单号不能为空');
      }
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空');
      }
      if (!this.formData.amount) {
        return this.$message.error('数量不能为空');
      } else if (this.formData.amount < 1) {
        return this.$message.error('数量必须大于0');
      }
      if (!this.formData.targetStore) {
        return this.$message.error('目标库区不能为空');
      }

      const {data: res} = await this.$axios.post('rulePriority/addRule', this.formData);
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.search();
      this.handleClose()
      this.formData.lendOrderCode = ''
      this.formData.materialCode = ''
      this.formData.assetSn = ''
      this.formData.amount = ''
      this.formData.targetStore = ''
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
}
</script>

<style scoped>
.input {
  width: 300px;
  margin-right: 10px;
}
</style>